<template>
    <section>
        <modal ref="modalHistorialCambioCupo" titulo="Historial de cupo de crédito asignado" tamano="modal-xl" icon="online-shop" no-cancelar no-aceptar>
            <div v-for="(data, index) in historial" :key="index" class="row mx-0 mb-3 ml-4 mr-4">
                <img :src="data.foto" class="obj-cover rounded-circle" width="50" height="50" />
                <div class="col-3">
                    <p class="f-500 f-17">
                        {{ data.creador }}
                    </p>
                    <p class="f-400 f-17">
                        {{ data.created_at | helper-fecha('DD MMM YYYY - h:mm a') }}
                    </p>
                </div>
                <div class="col-3">
                    <p class="f-500 f-17">
                        {{ data.cupo == 0 ? 'Ilimitado' : separadorNumeroDecimales(data.cupo) }}
                    </p>
                    <p class="f-400 f-17">
                        Cupo asignado
                    </p>
                </div>
                <div class="col-3">
                    <p class="f-500 f-17">
                        Comentario:
                    </p>
                    <p class="f-400 f-17">
                        {{ data.comentario }}
                    </p>
                </div>  
            </div>
        </modal>
    </section>
</template>
<script>
import Produccion from "~/services/tiendas/produccion";
import moment from 'moment'
export default {
    data(){
        return{
            historial: [],
        }
    },
    computed: {
        id_tienda(){
            return Number(this.$route.params.id_tienda)
        }
    },
    methods: {
        toggle(){
            this.getHistorial();
            this.$refs.modalHistorialCambioCupo.toggle();
        },
        async getHistorial(){
            try {
                const { data } = await Produccion.getHistorialCupo(this.id_tienda)
                this.historial = data.historial;
            } catch (error){
                this.error_catch(error)
            }
        }
    }
}
</script>
